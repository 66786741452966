.o-productDescription{
    background: $bg-white;
    font-size: 1.6rem;
}
.o-productDescription td{
    border: 0;
}
.o-productDescription th{
    border-bottom: 1px solid $border-grey;
    border-top: 0;
}
.o-productDescription p{
    font-weight: 500;
}

@media screen and (max-width:991px){

}