/* ========================================================================== */
/* BUTTONS */
/* ========================================================================== */

.btn{
    @include f-16-white-regular;
    font-weight: 600;
    min-height: 5.4rem;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;
    background: $bg-green;
    border-radius: $border-radius-primary;
    position: relative;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    white-space: normal;

    .a-btnIcon{
        background: transparent;
        min-height: 5.4rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 1.2rem;
        flex-shrink: 0;

        &.-rotate180{
            transform: rotate(180deg);
            margin-left: 0;
            margin-right: 1.2rem;
        }
    }
    .a-icon{
        display: inline-block;
        height: 8px;
        width: 5px;
    }
    &:hover, &:active, &:focus{
        color: $text-white;
        box-shadow: none;
        background: $green-dark;
    }
}
@media screen and (max-width: 600px){
    .btn{
        padding: 0 2rem;

        .a-btnIcon{
            margin-left: 2rem;


        }
    }
}
@media screen and (max-width: 400px){
    .btn{
        padding: 0 1.5rem;

        .a-btnIcon{
            margin-left: 1rem;
            margin-right: 0rem;

        }
    }
}
.btn.-fullWidth{
    width: 100%;
}
.m-cartButtons .btn.-fullWidth{
    flex-grow: 1;
    width: auto;
}
.btn.-small{
    min-height: 4.8rem;
    @include f-14-white-semibold;

    .a-btnIcon{
        min-height: 4.8rem;
    }
}

.btn.-light{
    background: $bg-light;
    color: $text-primary;

    &:hover, &:active, &:focus {
        opacity: 0.8;
    }
}
.btn.-transparent{
    background: transparent;
    overflow: hidden;

    &::after{
        content: "";
        @include absolute-position(0,0,0,0);
        opacity: 0.15;
        transition: 0.3s ease all;
    }
    &:hover, &:active, &:focus {
        &::after{
            opacity: 0.25;  
        }
    }
}

/* ========================================================================== */
/* BTN COLOR VARIATIONS */
/* ========================================================================== */

.btn.-secondary{
    background: $bg-brand-secondary;


    &:hover, &:active, &:focus{
        background: $bg-brand-secondary-dark;
    }
    &.-transparent{
        background: transparent;
        color: $text-brand-secondary;

        &::after{
            background: $bg-brand-secondary;

        }
    }
}

.btn.-dark{
    background: $bg-primary;

    &.-transparent{
        background: transparent;
        color: $text-dark;

        &::after{
            background: $bg-dark;
        }
    }  
}

.btn.-white{
    background: $bg-white;
    color: $text-primary;
}

.btn.-remove{
    background: $bg-red;

    &.-transparent{
        background: transparent;
        color: $text-red;

        &::after{
            background: $bg-red;
        }
    }
}

/* IE 10, 11 CSS */ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .btn{
        height: 5.4rem;
    }
}