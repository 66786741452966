section{
    padding: 6rem 0;
}
section h1{
    @include f-30-primary-semibold;
    text-align: center;
    margin-bottom: 4rem;
}
@media screen and (max-width:575px){
    section{
        padding: 2rem 0;
    }
    section h1{
        @include f-18-primary-semibold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 2rem;
    }
}