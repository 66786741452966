.o-navMain{
    background: $bg-primary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 72px;
    z-index: 5;
    position: relative;
    max-width: 1300px;
    margin: 0 auto;

}
@media screen and (max-width:991px){
    .o-navMain{
        height: 56px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6;
        box-shadow: $box-shadow-primary;
    }
}
