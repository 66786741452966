/* ========================================================================== */
/*  48 px */
/* ========================================================================== */

@mixin f-48-primary-regular() {
  font-size: 4.8rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-48-primary-semibold() {
  font-size: 4.8rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-48-dark-regular() {
  font-size: 4.8rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-48-dark-semibold() {
  font-size: 4.8rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-48-semibold-regular() {
  font-size: 4.8rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-48-semibold-semibold() {
  font-size: 4.8rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-48-white-regular() {
  font-size: 4.8rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-48-white-semibold() {
  font-size: 4.8rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  36 px */
/* ========================================================================== */

@mixin f-36-primary-regular() {
  font-size: 3.6rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-36-primary-semibold() {
  font-size: 3.6rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-36-dark-regular() {
  font-size: 3.6rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-36-dark-semibold() {
  font-size: 3.6rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-36-semibold-regular() {
  font-size: 3.6rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-36-semibold-semibold() {
  font-size: 3.6rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-36-white-regular() {
  font-size: 3.6rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-36-white-semibold() {
  font-size: 3.6rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  30 px */
/* ========================================================================== */

@mixin f-30-primary-regular() {
  font-size: 3rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-30-primary-semibold() {
  font-size: 3rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-30-dark-regular() {
  font-size: 3rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-30-dark-semibold() {
  font-size: 3rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-30-semibold-regular() {
  font-size: 3rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-30-semibold-semibold() {
  font-size: 3rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-30-white-regular() {
  font-size: 3rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-30-white-semibold() {
  font-size: 3rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  24 px */
/* ========================================================================== */

@mixin f-24-primary-regular() {
  font-size: 2.4rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-24-primary-semibold() {
  font-size: 2.4rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-24-dark-regular() {
  font-size: 2.4rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-24-dark-semibold() {
  font-size: 2.4rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-24-semibold-regular() {
  font-size: 2.4rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-24-semibold-semibold() {
  font-size: 2.4rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-24-white-regular() {
  font-size: 2.4rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-24-white-semibold() {
  font-size: 2.4rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  20 px */
/* ========================================================================== */

@mixin f-20-primary-regular() {
  font-size: 2rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-20-primary-semibold() {
  font-size: 2rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-20-dark-regular() {
  font-size: 2rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-20-dark-semibold() {
  font-size: 2rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-20-semibold-regular() {
  font-size: 2rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-20-semibold-semibold() {
  font-size: 2rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-20-white-regular() {
  font-size: 2rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-20-white-semibold() {
  font-size: 2rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  16 px */
/* ========================================================================== */

@mixin f-18-primary-regular() {
  font-size: 1.8rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-18-primary-semibold() {
  font-size: 1.8rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-18-dark-regular() {
  font-size: 1.8rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-18-dark-semibold() {
  font-size: 1.8rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-18-semibold-regular() {
  font-size: 1.8rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-18-semibold-semibold() {
  font-size: 1.8rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-18-white-regular() {
  font-size: 1.8rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-18-white-semibold() {
  font-size: 1.8rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/*  16 px */
/* ========================================================================== */

@mixin f-16-primary-regular() {
  font-size: 1.6rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-16-primary-semibold() {
  font-size: 1.6rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-16-dark-regular() {
  font-size: 1.6rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-16-dark-semibold() {
  font-size: 1.6rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-16-semibold-regular() {
  font-size: 1.6rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-16-semibold-semibold() {
  font-size: 1.6rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-16-white-regular() {
  font-size: 1.6rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-16-white-semibold() {
  font-size: 1.6rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/* 14 px */
/* ========================================================================== */

@mixin f-14-primary-regular() {
  font-size: 1.4rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-14-primary-semibold() {
  font-size: 1.4rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-14-dark-regular() {
  font-size: 1.4rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-14-dark-semibold() {
  font-size: 1.4rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-14-semibold-regular() {
  font-size: 1.4rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-14-semibold-semibold() {
  font-size: 1.4rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-14-white-regular() {
  font-size: 1.4rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-14-white-semibold() {
  font-size: 1.4rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}

/* ========================================================================== */
/* 12 px */
/* ========================================================================== */

@mixin f-12-primary-regular() {
  font-size: 1.2rem;
  color: $text-primary;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-12-primary-semibold() {
  font-size: 1.2rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-12-dark-regular() {
  font-size: 1.2rem;
  color: $text-dark;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-12-dark-semibold() {
  font-size: 1.2rem;
  color: $text-dark;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-12-semibold-regular() {
  font-size: 1.2rem;
  color: $text-medium;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-12-semibold-semibold() {
  font-size: 1.2rem;
  color: $text-medium;
  font-weight: 600;
  line-height: 1.5;
}

@mixin f-12-white-regular() {
  font-size: 1.2rem;
  color: $text-white;
  font-weight: 400;
  line-height: 1.5;
}
@mixin f-12-white-semibold() {
  font-size: 1.2rem;
  color: $text-white;
  font-weight: 600;
  line-height: 1.5;
}