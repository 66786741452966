@charset 'utf-8';

/* ========================================================================== */
/* VARIABLES */
/* ========================================================================== */

@import "variables/_colors.scss";
@import "variables/_fonts.scss";

/* ========================================================================== */
/* MIXINS */
/* ========================================================================== */

@import "mixins/_position.scss";
@import "mixins/_text-styles.scss";

/* ========================================================================== */
/* MAIN */
/* ========================================================================== */

.pswp__bg{
    background: rgba(0,0,0,0.9); 
}

/* ========================================================================== */
/* 00 BASE */
/* ========================================================================== */

@import "partials/00_base/_b-body.scss";
@import "partials/00_base/_b-box-sizing.scss";
@import "partials/00_base/_b-clear.scss";
@import "partials/00_base/_b-col.scss";
@import "partials/00_base/_b-container.scss";
@import "partials/00_base/_b-lists.scss";
@import "partials/00_base/_b-object-fit.scss";
@import "partials/00_base/_b-rem.scss";
@import "partials/00_base/_b-row.scss";
@import "partials/00_base/_b-selection.scss";
@import "partials/00_base/_b-typography.scss";

/* ========================================================================== */
/* 01 ATOM */
/* ========================================================================== */

@import "partials/01_atom/_a-cartNavResponsivePush.scss";
@import "partials/01_atom/_a-cartResponsiveError.scss";
@import "partials/01_atom/_a-checkbox.scss";
@import "partials/01_atom/_a-darkOverlay.scss";
@import "partials/01_atom/_a-icons.scss";
@import "partials/01_atom/_a-images.scss";
@import "partials/01_atom/_a-links.scss";
@import "partials/01_atom/_a-navPush.scss";
@import "partials/01_atom/_a-radiobutton.scss";
@import "partials/01_atom/_a-range.scss";
@import "partials/01_atom/_a-scrollTop.scss";
@import "partials/01_atom/_a-switch.scss";
@import "partials/01_atom/_a-tags.scss";
@import "partials/01_atom/_a-tooltips.scss";

/* ========================================================================== */
/* 02 MOLECULE */
/* ========================================================================== */

@import "partials/02_molecule/_m-alerts.scss";
@import "partials/02_molecule/_m-breadcrumb.scss";
@import "partials/02_molecule/_m-buttons.scss";
@import "partials/02_molecule/_m-cartBarResponsive.scss";
@import "partials/02_molecule/_m-cartButtons.scss";
@import "partials/02_molecule/_m-cartDeliveryItem.scss";
@import "partials/02_molecule/_m-cartDeliveryOverview.scss";
@import "partials/02_molecule/_m-cartFreeShipping.scss";
@import "partials/02_molecule/_m-cartItemSingle.scss";
@import "partials/02_molecule/_m-cartItemTop.scss";
@import "partials/02_molecule/_m-cartOverview.scss";
@import "partials/02_molecule/_m-cartPriceOverview.scss";
@import "partials/02_molecule/_m-cartUserItem.scss";
@import "partials/02_molecule/_m-categoryCell.scss";
@import "partials/02_molecule/_m-contactItem.scss";
@import "partials/02_molecule/_m-cookieBar.scss";
@import "partials/02_molecule/_m-dropdowns.scss";
@import "partials/02_molecule/_m-filterBadges.scss";
@import "partials/02_molecule/_m-filterResponsive.scss";
@import "partials/02_molecule/_m-navCart.scss";
@import "partials/02_molecule/_m-navLogo.scss";
@import "partials/02_molecule/_m-navProductClose.scss";
@import "partials/02_molecule/_m-navSearch.scss";
@import "partials/02_molecule/_m-navSearchToogler.scss";
@import "partials/02_molecule/_m-navToggler.scss";
@import "partials/02_molecule/_m-navUser.scss";
@import "partials/02_molecule/_m-navUserMenu.scss";
@import "partials/02_molecule/_m-newsSingle.scss";
@import "partials/02_molecule/_m-numberStepper.scss";
@import "partials/02_molecule/_m-pagination.scss";
@import "partials/02_molecule/_m-productCarousel.scss";
@import "partials/02_molecule/_m-productCell.scss";
@import "partials/02_molecule/_m-productDetailCarousel.scss";
@import "partials/02_molecule/_m-productDetailInfo.scss";
@import "partials/02_molecule/_m-productDetailMain.scss";
@import "partials/02_molecule/_m-productDetailResponsive.scss";
@import "partials/02_molecule/_m-productDetailThumbs.scss";
@import "partials/02_molecule/_m-productVariation.scss";
@import "partials/02_molecule/_m-rating.scss";
@import "partials/02_molecule/_m-registerBanner.scss";
@import "partials/02_molecule/_m-selectBox.scss";
@import "partials/02_molecule/_m-socialShare.scss";
@import "partials/02_molecule/_m-tabsNav.scss";
@import "partials/02_molecule/_m-userSidebar.scss";

/* ========================================================================== */
/* 03 ORGANISM */
/* ========================================================================== */

@import "partials/03_organism/_o-cartDeliveryForm.scss";
@import "partials/03_organism/_o-cartEmpty.scss";
@import "partials/03_organism/_o-cartNav.scss";
@import "partials/03_organism/_o-cartNavResponsive.scss";
@import "partials/03_organism/_o-cartUsefInfo.scss";
@import "partials/03_organism/_o-cartUserNotes.scss";
@import "partials/03_organism/_o-cartUserOverview.scss";
@import "partials/03_organism/_o-categories.scss";
@import "partials/03_organism/_o-copyright.scss";
@import "partials/03_organism/_o-filter.scss";
@import "partials/03_organism/_o-footer.scss";
@import "partials/03_organism/_o-form.scss";
@import "partials/03_organism/_o-header.scss";
@import "partials/03_organism/_o-hero.scss";
@import "partials/03_organism/_o-modal.scss";
@import "partials/03_organism/_o-navMain.scss";
@import "partials/03_organism/_o-navProducts.scss";
@import "partials/03_organism/_o-pageHeading.scss";
@import "partials/03_organism/_o-productDescription.scss";
@import "partials/03_organism/_o-productDetail.scss";
@import "partials/03_organism/_o-productDetailVariants.scss";
@import "partials/03_organism/_o-productGrid.scss";
@import "partials/03_organism/_o-productPagination.scss";
@import "partials/03_organism/_o-sectionNews.scss";
@import "partials/03_organism/_o-sectionProducts.scss";
@import "partials/03_organism/_o-sectionSale.scss";
@import "partials/03_organism/_o-subCategories.scss";
@import "partials/03_organism/_o-tabs.scss";
@import "partials/03_organism/_o-userHistory.scss";

/* ========================================================================== */
/* 04 TEMPLATE */
/* ========================================================================== */

@import "partials/04_template/_t-article.scss";
@import "partials/04_template/_t-main.scss";
@import "partials/04_template/_t-print.scss";
@import "partials/04_template/_t-product-grid.scss";
@import "partials/04_template/_t-section.scss";

/* ========================================================================== */
/* 05 PAGE */
/* ========================================================================== */

@import "partials/05_page/_p-blog.scss";
@import "partials/05_page/_p-cart.scss";
@import "partials/05_page/_p-home.scss";
@import "partials/05_page/_p-product-category.scss";
@import "partials/05_page/_p-product-detail.scss";
