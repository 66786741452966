.o-cartDeliveryForm{
    margin-top: -4rem;
    padding-right: 4rem;
}
.o-cartDeliveryForm__title{
    @include f-24-primary-semibold;
    margin: 4rem 0 2rem 0;
}
.o-cartDeliveryForm__col{
    margin: 4rem 0;
}

.o-cartDeliveryForm__item{
    padding-bottom: 1px;
    margin: 0;
    margin-bottom: -10px;
}
@media screen and (max-width: 768px){
    .o-cartDeliveryForm__title{
        text-align: center;
    }
}