.a-radiobutton{
    height: 14px;
    width: 14px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    flex-shrink: 0;
    margin-top: 4px;
    z-index: 1;

    &::before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border: 2px solid $border-medium;
        border-radius: 50%;
    }
    &::after{
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        background: $bg-brand;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        opacity: 0;
        transition: 0.2s ease all;
    }
}