.m-selectBox{
    background: $bg-light;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.m-selectBox__honeypot{
    position: absolute;
    opacity: 0;
}
.m-selectBox__dropdown{
    height: 100%;
    min-height: 5.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2rem;
    @include f-16-primary-regular;
}
.m-selectBox__icon{
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    height: 10px;
}
.m-selectBox__dropdownMenu{
    width: 100%;
    border: 0;
    background: $bg-light;
    box-shadow: $box-shadow-primary;
    padding: 0;
}
.m-selectBox__label{
    width: 100%;
    margin: 0;
    cursor: pointer;
}
.m-selectBox__input{
    position: absolute;
    opacity: 0;
}
.m-selectBox__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 4rem;
    padding: 5px 2rem;

    &:hover, &:active, &:focus{
        background: $bg-white;
    }
}
.m-selectBox__name{
    flex-grow: 1;
    flex-basis: 0;
    @include f-14-primary-semibold;
}
.m-selectBox__price, .m-selectBox__stock{
    padding-left: 20px;
    @include f-14-primary-semibold;
}
.m-selectBox__priceOld{
    @include f-12-dark-regular;
    line-height: 1;
    text-decoration: line-through;
}
.m-selectBox__priceNew{
    @include f-16-primary-semibold;
    color: $text-red;
}
.m-selectBox__stock{
    color: $text-green;
    width: 100px;
}
.m-selectBox__input:checked + .m-selectBox__content{
    background: $bg-brand-secondary;

    & .m-selectBox__name, & .m-selectBox__priceOld, & .m-selectBox__priceNew, & .m-selectBox__stock{
        color: $text-white;
    }
}